import { Site } from '../../types/Site';

export const getArticleId = (site: Site, urlArticleId?: string): string | null => {
  if (urlArticleId) {
    if (!window.analytics_ga_data) {
      window.analytics_ga_data = {};
    }
    return urlArticleId;
  }

  if (typeof window !== 'undefined' && window?.dfp_config?.article_id) {
    return window.dfp_config.article_id;
  }

  // Get the keywords for non vanilla sites
  const gaData = window.analytics_ga_data;

  if (gaData) {
    switch (site?.code) {
      case 'decanter':
      case 'horseandhound':
      case 'mbr':
      case 'mby':
      case 'pbo':
      case 'rugbyworld':
      case 'yachtingmonthly':
      case 'yachtingworld':
      case 'ybw':
        return gaData.dimension10;
      case 'mozo':
        return gaData.dimension8;
      case 'pricepandaid':
      case 'pricepandath':
        return gaData.dimension1;
      case 'getprice':
      case 'getpricenz':
        return gaData.dimension26;
      case 'pricepandasg':
      case 'pricepandamy':
      case 'pricepandaph':
        return gaData.dimension21;
      case 'shortlist':
        return gaData.dimension100;
      default:
        return null;
    }
  }
  return null;
};
