import type { Site } from '../../types/Site';

import BaseMerchant from './BaseMerchant';

export default class CJMerchant extends BaseMerchant {
  mid: string | number | undefined;

  accountId?: string | number;

  trackingId?: string | number;

  constructor(
    id: number,
    name: string,
    mid: string | number | undefined,
    hostname: string,
    path?: string | null | undefined,
    site?: Site,
    accountId?: string | number,
    trackingId?: string | number,
  ) {
    super(id, name, hostname, site, path);
    this.mid = mid;
    this.trackedLinkPattern = 'anrdoezrs.net';
    this.accountId = accountId;
    this.trackingId = trackingId;
  }

  rewrite(url: string): string {
    if (this.trackedLinkPattern && this.accountId) {
      // Alternative CJ with custom tracking id - https://www.anrdoezrs.net/click-6361382-14363414?url=https%3A%2F%2Fstore.google.com%2Fproduct%2Fgoogle_nest_hub_max
      if (this.trackingId && this.accountId) {
        return (
          `https://www.${this.trackedLinkPattern}` +
          `/click-${this.accountId}-${this.trackingId}` +
          `?sid=hawk-custom-tracking&url=${url}`
        );
      }
      if (this.accountId) {
        return `${
          `https://www.${this.trackedLinkPattern}` +
          `/links/${this.accountId}` +
          '/type/dlg' +
          '/sid/hawk-custom-tracking/'
        }${url}`;
      }
    }
    return url;
  }
}
