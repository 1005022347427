import Uri from 'urijs';

import type { Site } from '../../types/Site';

import BaseMerchant from './BaseMerchant';

export default class AdMarketplaceMerchant extends BaseMerchant {
  trackingId?: string | number;

  constructor(
    id: number,
    name: string,
    hostname: string,
    path?: string | null,
    trackingId?: string | number,
    site?: Site,
  ) {
    super(id, name, hostname, site, path);
    this.trackingId = trackingId;
    this.trackedLinkPattern = 'futureplc.ampxdirect.com';
  }

  /**
   * @param {string} url
   * @returns {string}
   */
  rewrite(url: string): string {
    // https://futureplc.ampxdirect.com/mbusa?sub1=gamesradar&sub2=mercedes&sub3=dynamiclink&cu=https%3A%2F%2Fwww.mbusa.com%2Fen%2Fspecial-offers&fbu=https%3A%2F%2Fwww.mbusa%2Fen%2Fspecial-offers
    if (this.site && this.site.name) {
      const urlObject = new Uri(`https://${this.trackedLinkPattern}/${this.trackingId}`);
      urlObject.addQuery('sub1', this.site && this.site.name);
      urlObject.addQuery('sub2', this.trackingId);
      urlObject.addQuery('sub3', 'dynamiclink');
      urlObject.addQuery('custom-data', 'hawk-custom-tracking');
      urlObject.addQuery('cu', url);
      urlObject.addQuery('fbu', url);
      return urlObject.toString();
    }

    return url;
  }
}
