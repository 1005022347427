import Uri from 'urijs';

import type { Site } from '../../types/Site';

import BaseMerchant from './BaseMerchant';

export default class FlipKartMerchant extends BaseMerchant {
  constructor(
    id: number,
    name: string,
    mid: string | number | undefined,
    hostname: string,
    path?: string | null,
    site?: Site,
  ) {
    super(id, name, hostname, site, path);
  }

  rewrite(url: string): string {
    if (url.search('affid') === -1) {
      const urlObject = new Uri(url);

      // Reset the affid tracking
      urlObject.removeQuery('affid');
      urlObject.addQuery('affid', 'ecommerce19');
      urlObject.removeQuery('affExtParam1');
      urlObject.addQuery('affExtParam1', 'hawk-custom-tracking');

      return urlObject.toString();
    }

    return url;
  }
}
