import { getJSON } from '../apiUtils';

type SkimResourcesResponse = {
  merchant_domains: string[];
};

export const fetchSkimlinksMerchantDomains = async ({
  skimlinksId,
  documentDomains,
  url,
}: {
  skimlinksId: string;
  documentDomains: string[];
  url: string;
}): Promise<string[]> => {
  const response = await getJSON<SkimResourcesResponse>('https://r.skimresources.com/api/', {
    persistence: 1,
    data: JSON.stringify({
      pubcode: skimlinksId,
      domains: documentDomains,
      page: encodeURIComponent(url),
    }),
  });
  if (!response.ok) {
    throw new Error('Failed to fetch skimlinks merchant domains');
  }
  return response.data.merchant_domains;
};
