import AdMarketplaceMerchant from '../hawklinks/merchants/AdMarketplaceMerchant';
import AmazonMerchant from '../hawklinks/merchants/AmazonMerchant';
import AWMerchant from '../hawklinks/merchants/AWMerchant';
import BaseMerchant from '../hawklinks/merchants/BaseMerchant';
import BolcomMerchant from '../hawklinks/merchants/BolcomMerchant';
import CableguysMerchant from '../hawklinks/merchants/CableguysMerchant';
import CJMerchant from '../hawklinks/merchants/CJMerchant';
import EbayMerchant from '../hawklinks/merchants/EbayMerchant';
import FlipKartMerchant from '../hawklinks/merchants/FlipKartMerchant';
import GenericMerchant from '../hawklinks/merchants/GenericMerchant';
import HasOffersMerchant from '../hawklinks/merchants/HasOffersMerchant';
import ImpactRadMerchant from '../hawklinks/merchants/ImpactRadMerchant';
import InvolveAsiaMerchant from '../hawklinks/merchants/InvolveAsiaMerchant';
import LSMerchant from '../hawklinks/merchants/LSMerchant';
import NordMerchant from '../hawklinks/merchants/NordMerchant';
import OptimiseMediaMerchant from '../hawklinks/merchants/OptimiseMediaMerchant';
import PartnerAdsMerchant from '../hawklinks/merchants/PartnerAdsMerchant';
import PHGMerchant from '../hawklinks/merchants/PHGMerchant';
import PrivadoMerchant from '../hawklinks/merchants/PrivadoMerchant';
import ScalaHostingMerchant from '../hawklinks/merchants/ScalaHostingMerchant';
import { Site } from '../types/Site';

export interface MerchantDomain extends BaseMerchant {
  external_reference?: string | number;
  parameters?: Record<string, string>;
  target_domain?: string;
  tracking_path?: string;
  tracking_id?: string | number;
  account_id?: string | number;
  merchant_name: string;
}

class Merchants {
  private createMerchant = (
    id: BaseMerchant['id'],
    externalReference: string | number | undefined,
    network: BaseMerchant['network'],
    url: BaseMerchant['url'],
    path: BaseMerchant['path'],
    merchantName: BaseMerchant['name'],
    parameters: Record<string, string> | undefined,
    targetDomain: string | undefined,
    trackingPath: string | undefined,
    trackingId: string | number | undefined,
    accountId: string | number | undefined,
    site: Site,
    tsid: string | undefined,
  ): BaseMerchant | null => {
    switch (network) {
      case 'Amazon':
        return new AmazonMerchant(
          id,
          String(externalReference),
          tsid,
          url,
          site,
          targetDomain,
          trackingId,
        );
      case 'AW':
        return new AWMerchant(id, merchantName, externalReference, url, path, site, accountId);
      case 'CJ':
        return new CJMerchant(
          id,
          merchantName,
          externalReference,
          url,
          path,
          site,
          accountId,
          trackingId,
        );
      case 'Ebay':
        return new EbayMerchant(
          id,
          merchantName,
          externalReference,
          url,
          path,
          site,
          accountId,
          trackingId,
        );
      case 'LS':
        return new LSMerchant(id, merchantName, externalReference, trackingId, url, path, site);
      case 'HasOffers':
        return new HasOffersMerchant(
          id,
          merchantName,
          externalReference,
          url,
          path,
          site,
          targetDomain,
          accountId,
          parameters,
        );
      case 'FlipKart':
        return new FlipKartMerchant(id, merchantName, externalReference, url, path, site);
      case 'ImpactRad':
        return new ImpactRadMerchant(
          id,
          merchantName,
          url,
          externalReference,
          accountId,
          trackingId,
          targetDomain,
          path,
          site,
        );
      case 'PHG':
        return new PHGMerchant(
          id,
          merchantName,
          url,
          externalReference,
          trackingId,
          path,
          targetDomain,
          site,
        );
      case 'Generic':
        return new GenericMerchant(
          id,
          merchantName,
          url,
          path,
          parameters,
          targetDomain,
          site,
          trackingPath,
        );
      case 'Nord':
        return new NordMerchant(id, merchantName, url, path, site);
      case 'BolCom':
        return new BolcomMerchant(id, merchantName, url, path, site, accountId);
      case 'AdMarketplace':
        return new AdMarketplaceMerchant(id, merchantName, url, path, trackingId, site);
      case 'PartnerAds':
        return new PartnerAdsMerchant(
          id,
          merchantName,
          url,
          path,
          targetDomain,
          site,
          trackingId,
          parameters,
        );
      case 'ScalaHosting':
        return new ScalaHostingMerchant(id, merchantName, url, site, path);
      case 'Cableguys':
        return new CableguysMerchant(id, merchantName, url, path, parameters, targetDomain, site);
      case 'OptimiseMedia':
        return new OptimiseMediaMerchant(
          id,
          merchantName,
          url,
          trackingId,
          targetDomain,
          path,
          site,
        );
      case 'InvolveAsia':
        return new InvolveAsiaMerchant(id, merchantName, url, externalReference, site, path);
      case 'Privado':
        return new PrivadoMerchant(id, merchantName, url, path, parameters, targetDomain, site);
      default:
        return null;
    }
  };

  public getMerchants = (
    tsid: string | undefined,
    merchants: MerchantDomain[],
    site: Site,
  ): Record<string, BaseMerchant[]> => {
    const merchantConfigs = {};

    merchants
      .filter((merchant) => merchant.url && merchant.network)
      .forEach((merchant) => {
        const {
          id,
          external_reference: externalReference,
          network,
          url,
          parameters,
          merchant_name: merchantName,
          target_domain: targetDomain,
          tracking_path: trackingPath,
          tracking_id: trackingId,
          account_id: accountId,
        } = merchant;
        let { path } = merchant;

        // Must set path to empty string otherwise links will be broken (e.g. misco.co.uknull)
        path = path || '';

        const createdMerchant = this.createMerchant(
          id,
          externalReference,
          network,
          url,
          path,
          merchantName ?? url,
          parameters,
          targetDomain,
          trackingPath,
          trackingId,
          accountId,
          site,
          tsid,
        );
        if (createdMerchant) {
          // Set the network for the merchant so we have access to it for Analytics purposes later.
          // Set here to avoid us doing the same thing for every merchant constructor
          createdMerchant.network = String(network);
          if (!merchantConfigs[createdMerchant.getMerchantDomain()]) {
            merchantConfigs[createdMerchant.getMerchantDomain()] = [];
          }
          merchantConfigs[createdMerchant.getMerchantDomain()].push(createdMerchant);
        }
      });

    return merchantConfigs;
  };
}

export default Merchants;
