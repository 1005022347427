import getNodes from './getNodes';

interface GetLinksResult {
  widgets: HTMLElement[];
  urls: string[];
  linksToProcess: HTMLAnchorElement[];
}

export const getLinks = (selector: string, context?: HTMLElement | Document): GetLinksResult => {
  const widgets = getNodes<HTMLElement>('.hawk-placeholder,.hawk-nest,.hawk-base', context);
  const links = getNodes<HTMLAnchorElement>(selector, context);
  const linksToProcess: HTMLAnchorElement[] = [];
  // Domains of links on the page for SkimLinks
  const urls: string[] = [];
  links.forEach((el) => {
    if (!widgets.some((widget) => widget.contains(el))) {
      urls.push(el.href);
      linksToProcess.push(el);
    }
  });

  return {
    widgets,
    urls,
    linksToProcess,
  };
};
