import { Site } from '../../types/Site';

export default (site: Site, keywords: string[]): Site => {
  if (site.code === 'trd' && keywords.includes('TRBC')) {
    return {
      ...site,
      analyticsName: `${site.analyticsName}pro`,
    };
  }
  return site;
};
