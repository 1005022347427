import Uri from 'urijs';

import { markPerformanceAPI } from '../../analytics-library/entry';
import getUrl from '../../hawklinks/browser/getUrl';
import Merchant from '../../hawklinks/merchants/BaseMerchant';
import SLReset from '../../hawklinks/resets/SLReset';
import { fetchSkimlinksMerchantDomains } from '../../sharedModules/api/skimlinks/fetchSkimlinksMerchantDomains';
import { Site } from '../../types/Site';
import {
  isAlreadyAffiliatedWithPlaceholderTracking,
  isAlreadyAffiliatedWithRewrittenTracking,
} from '../isAlreadyAffiliated';

import { BaseReset } from './hawklinks';
import { LinkProcessor, ProcessedLink } from './LinkProcessor';

/**
 * Creates a function that accepts a link url and document url and returns the rewritten skimlink
 */
const createRewrite =
  (skimlinksId?: string) =>
  (linkUrl: string, documentUrl: string): string => {
    const urlObject = new Uri('https://go.redirectingat.com');
    urlObject.addQuery('id', skimlinksId);
    urlObject.addQuery('xcust', 'hawk-custom-tracking');
    urlObject.addQuery('xs', 1);
    urlObject.addQuery('url', linkUrl);
    urlObject.addQuery('sref', documentUrl);

    return urlObject.toString();
  };

const getDomains = (linkUrls: string[], reset: BaseReset): string[] => {
  const domains = new Set<string>();
  linkUrls.forEach((url) => {
    const resetUrl = reset.getResetUrl(url);
    const uri = new Uri(resetUrl || url);
    const domain = uri.domain();
    domains.add(domain);
  });

  return Array.from(domains);
};

export class SkimLinks extends LinkProcessor {
  private domain: string;

  private skimlinksId: string | undefined;

  private getRewrittenLink: (linkUrl: string, documentUrl: string) => string;

  private rewriteAllDomains: boolean;

  private site: Site;

  private reset: BaseReset;

  private documentDomains: string[];

  private skimlinksMerchantDomains?: Promise<string[]>;

  constructor(
    domain: string,
    skimlinksId: string | undefined,
    rewriteAllDomains: boolean,
    linkUrls: string[],
    site: Site,
  ) {
    super();

    this.domain = domain;
    this.skimlinksId = skimlinksId;
    this.getRewrittenLink = createRewrite(skimlinksId);
    this.rewriteAllDomains = rewriteAllDomains;
    this.site = site;
    this.reset = new SLReset();
    this.documentDomains = getDomains(linkUrls, this.reset);
  }

  /**
   * Determines whether a link can be rewritten
   * Rejects empty links, links to the same domains as the document, javascript:void(0) links
   * Rejects links with a domain on a blacklist or custom tracking (e.g. rewritten in widgets)
   */
  public isRewritable = (linkUrl: string): boolean => {
    // skip anchor links, links to the same domain and links with rewritten custom tracking
    if (
      linkUrl === '' ||
      linkUrl.indexOf('#') === 0 ||
      linkUrl.indexOf(this.domain) !== -1 ||
      isAlreadyAffiliatedWithRewrittenTracking(linkUrl, this.site)
    ) {
      return false;
    }
    // Do not let SkimLinks rewrite:
    //  - MFM (.co.uk and .com) links, as we own the site
    //  - ShoppingPartners links (MRD/DOTW), as they have exclusive contract with us
    //  - GetPrice, since they are CPC and we deal with them directly
    // Getty images - HAWK-3386
    // Istock photo - HAWK-3386
    const elUri = new Uri(linkUrl);
    const elHostname = elUri.hostname();
    const blacklistedUrls = [
      'myfavouritemagazines',
      'shoppingpartners2.futurenet.com',
      'go2jump.org',
      'getprice.com.au',
      'whistleout',
      'istockphoto.7eer.net',
      'istockphoto.com',
      'trk.aclktrkr.com',
      'gettyimages.com',
      'prf.hn',
      'tsohost.com',
      'adfarm.mediaplex.com',
      'altfarm.mediaplex.com',
    ];
    let stopRewrite = false;

    blacklistedUrls.forEach((url) => {
      if (elHostname.indexOf(url) >= 0) {
        stopRewrite = true;
      }
    });

    // Don't let skimlinks rewrite the link
    if (stopRewrite) {
      return false;
    }

    const elDomain = elUri.domain();

    // If domain is not set (such as "javascript:void(0)"), do not rewrite
    return Boolean(elDomain);
  };

  /**
   * Lazy getter for list of SkimLinks merchants
   */
  public getListOfMerchantDomains = async (url: string): Promise<string[]> => {
    if (!this.skimlinksMerchantDomains) {
      if (this.skimlinksId === undefined) {
        // Return empty list of merchants if no SkimLinks ID has been provided
        this.skimlinksMerchantDomains = Promise.resolve([]);
      } else {
        this.skimlinksMerchantDomains = fetchSkimlinksMerchantDomains({
          skimlinksId: this.skimlinksId,
          documentDomains: this.documentDomains,
          url,
        });
      }
    }

    return this.skimlinksMerchantDomains;
  };

  public processElement = async (el: HTMLAnchorElement): Promise<ProcessedLink> => {
    // No ID means that SkimLinks hasn't been initialised, skip processing
    if (this.skimlinksId === undefined) {
      return {
        url: el.href,
        merchant: null,
        rewritten: false,
        source: 'skimlinks',
      };
    }

    // Link is affiliated inside a widget
    if (isAlreadyAffiliatedWithPlaceholderTracking(el.getAttribute('data-url') || '')) {
      return {
        url: el.href,
        merchant: null,
        rewritten: false,
        source: 'skimlinks',
      };
    }

    // The link has already been processed (hawklinks), or should not be processed (sponsored)
    if (el.getAttribute('data-hl-processed')) {
      return {
        url: el.href,
        merchant: null,
        rewritten: false,
        source: 'skimlinks',
      };
    }

    // skip already parsed links
    if (el.classList.contains('hawk-link-parsed')) {
      return {
        url: el.href,
        merchant: null,
        rewritten: false,
        source: 'skimlinks',
      };
    }

    if (el.getAttribute('data-no-affiliate-tracking')) {
      return {
        url: el.href,
        merchant: null,
        rewritten: false,
        source: 'skimlinks',
      };
    }

    const url = document.URL;

    if (this.rewriteAllDomains) {
      // Process the link immediately (regardless of the supported domains)
      return this.rewriteEl(el, []);
    }

    // Process the link as soon as the merchant domain list is available
    try {
      const merchantDomains = await this.getListOfMerchantDomains(url);
      return this.rewriteEl(el, merchantDomains);
    } catch (_) {
      // Ensure the SkimLinks error doesn't break rewriting of other links
      return {
        url: el.href,
        merchant: null,
        rewritten: false,
        source: 'skimlinks',
      };
    }
  };

  public processLink = (
    url: string,
    merchantDomains: string[] = [],
    documentURL: string,
  ): ProcessedLink => {
    if (this.skimlinksId === undefined) {
      return {
        url,
        merchant: null,
        rewritten: false,
        source: 'skimlinks',
      };
    }

    /* Use the reset url for isRewritable to avoid the link being skipped because of domain
     * SL links include the page url as a param
     **/
    const resetUrl = this.reset.getResetUrl(url);
    const linkUrl = resetUrl || url;

    if (!this.isRewritable(linkUrl)) {
      return {
        url,
        merchant: null,
        rewritten: false,
        source: 'skimlinks',
      };
    }

    const elUri = new Uri(linkUrl);
    const elDomain = elUri.domain();

    if (this.rewriteAllDomains || merchantDomains.indexOf(elDomain) >= 0) {
      return {
        url: this.getRewrittenLink(linkUrl, documentURL),
        merchant: {
          name: `SkimLinks - ${elDomain}`,
          network: 'SkimLinks',
        } as Merchant,
        rewritten: true,
        source: 'skimlinks',
      };
    }

    return {
      url,
      merchant: null,
      rewritten: false,
      source: 'skimlinks',
    };
  };

  private rewriteEl = (el: HTMLAnchorElement, merchantDomains: string[] = []): ProcessedLink => {
    markPerformanceAPI('Skimlink Rewrite started', { detail: 'HAWKLINKS' });
    const result = this.processLink(getUrl(el), merchantDomains, document.URL);
    markPerformanceAPI('Skimlink Rewrite completed', { detail: 'HAWKLINKS' });
    if (result.rewritten) {
      el.setAttribute('data-url', el.href);
      el.href = result.url;
      if (result.merchant) {
        el.setAttribute('data-merchant-name', result.merchant.name);
      }
      el.setAttribute('data-hl-processed', 'skimlinks');

      return result;
    }

    return result;
  };
}
