import Uri from 'urijs';

import { Site } from '../../types/Site';

import BaseMerchant from './BaseMerchant';

export default class InvolveAsiaMerchant extends BaseMerchant {
  mid: string | number | undefined;

  constructor(
    id: number,
    name: string,
    hostname: string,
    mid: string | number | undefined,
    site?: Site,
    path?: string | null,
  ) {
    super(id, name, hostname, site, path);
    this.mid = mid;
  }

  rewrite(url: string): string {
    const urlObject = new Uri('https://invol.co/aff_m');
    urlObject.addQuery('offer_id', this.mid);
    urlObject.addQuery('aff_id', 2073);
    urlObject.addQuery('aff_sub', 'hawk-custom-tracking');
    urlObject.addQuery('source', 'deeplink');
    const innerUrlObject = new Uri(url);
    urlObject.addQuery('url', Uri.decode(innerUrlObject.toString()));
    return urlObject.toString();
  }
}
