import getGoogleClickId from '../../utils/getGoogleClickId';
import getUserAgent from '../../utils/getUserAgent';

import { getDataHash } from './getDataHash';

export interface GetCustomTrackingIdParams {
  params?: Record<string, unknown>;
  forcedCustomTrackingId?: string | null;
  date?: Date;
  randomize?: boolean;
}

export const getCustomTrackingId = async ({
  params = {},
  forcedCustomTrackingId = null,
  date = new Date(),
  randomize = false,
}: GetCustomTrackingIdParams): Promise<string> => {
  // Allow forcing the custom tracking for tests
  if (forcedCustomTrackingId) {
    return Promise.resolve(forcedCustomTrackingId);
  }

  // Use the user agent for the generate data hash - HAWK-5103
  params = params || {};
  params = {
    ...params,
    date: `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`,
    userAgent: getUserAgent(),
    gclid: getGoogleClickId(),
    ...(randomize && { randomNumber: Math.random() }),
  };

  // Remove metrics - generally based on time which prevents the same id from being generated
  // E.g. time since page load, viewport time, deal time remaining
  for (const key in params) {
    if (key.search(/metric/g) >= 0) {
      delete params[key];
    }
  }

  return getDataHash(params);
};
