import type { Site } from '../../types/Site';
import getUrlParameters from '../../utils/getUrlParameters';
import { HawkLinks } from '../../utils/linkProcessor/hawklinks';
import { SkimLinks } from '../../utils/linkProcessor/skimlinks';
import { MerchantDomain } from '../../utils/merchants';

import { getArticleId } from './getArticleId';
import { getLinks } from './getLinks';
import { handleInfiniteScrollArticle } from './handleInfiniteScrollArticle';
import { captureMetrics } from './metrics';
import processLinks from './processLinks';

interface InitOptions {
  merchants: MerchantDomain[];
  area: string;
  tsid?: string;
  skimlinksId?: string;
  site: Site;
}

const init = async (options: InitOptions): Promise<void> => {
  const { merchants, area, tsid, skimlinksId } = options;
  let { site } = options;
  site = site || {};
  const { selector } = site;

  const articleName = document.title;
  const articleUrl = document.location.href;

  const { article_id: urlArticleId } = getUrlParameters();
  const articleId = getArticleId(site, urlArticleId);

  // Setup globals for easier debugging
  if (!window.hawklinks) {
    window.hawklinks = {};
  }
  window.hawklinks = {
    ...window.hawklinks,
    version: process.env.VERSION,
    site,
    articleName,
    articleUrl,
    tsid,
    skimlinksId,
  };

  // Get links that are not in a widget.
  const { linksToProcess, widgets, urls } = getLinks(selector);

  // Initialize processors = HawkLinks + SkimLinks
  const hawkLinks = new HawkLinks(document.domain, tsid, merchants, site);
  const skimLinksWithDomainCheck = new SkimLinks(document.domain, skimlinksId, false, urls, site);
  const processors = [hawkLinks, skimLinksWithDomainCheck];

  // Rewrite existing links with HawkLinks + SkimLinks right away
  await processLinks(
    linksToProcess,
    processors,
    site,
    area,
    articleName,
    articleUrl,
    widgets,
    articleId,
  );

  handleInfiniteScrollArticle({
    selector,
    processors,
    site,
    area,
    articleId,
    articleName,
    articleUrl,
  });

  captureMetrics({
    site: site.code,
    type: 'hawklinks',
  });
};

export default init;
