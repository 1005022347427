import Merchant from '../../hawklinks/merchants/BaseMerchant';

export interface ProcessedLink {
  url: string;
  rewritten: boolean;
  merchant: Merchant | null;
  source: string;
}

export abstract class LinkProcessor {
  abstract processLink(url: string, merchantDomains: string[], documentURL: string): ProcessedLink;

  abstract processElement(el: HTMLAnchorElement): Promise<ProcessedLink>;
}
