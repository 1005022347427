import { Context } from '../../../hawklinks/node/utils/model';
import { Site } from '../../../types/Site';
import { getSearchAPIEndpoint } from '../../../utils/getSearchAPIEndpoint';
import { MerchantDomain } from '../../../utils/merchants';
import { GetJSON } from '../apiUtils';

interface MerchantsApiResponse {
  merchants: MerchantDomain[];
  config: {
    tsid?: string;
    skimlinks_id?: string;
  };
  area: string;
}

export interface MerchantsResponse {
  merchants: MerchantDomain[];
  tsid?: string;
  skimlinksId?: string;
  area: string;
}

export const createMerchantsGetter =
  (getJSON: GetJSON<MerchantsApiResponse>) =>
  async ({
    area,
    site,
    context,
  }: {
    area?: string;
    site: Site;
    context?: Context;
  }): Promise<MerchantsResponse> => {
    const response = await getJSON(`${getSearchAPIEndpoint()}/merchant-domains.php`, {
      area,
      site: site.code.toUpperCase(),
      context,
    });

    if (!response.ok) {
      throw new Error("Couldn't fetch merchants data");
    }

    return {
      merchants: response.data.merchants,
      tsid: response.data.config.tsid,
      skimlinksId: response.data.config.skimlinks_id,
      area: response.data.area,
    };
  };

export type MerchantsGetter = ReturnType<typeof createMerchantsGetter>;
