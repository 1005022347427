import Uri from 'urijs';

import type { Site } from '../../types/Site';

import BaseMerchant from './BaseMerchant';

export default class OptimiseMediaMerchant extends BaseMerchant {
  trackingId?: string | number;

  constructor(
    id: number,
    name: string,
    hostname: string,
    trackingId?: string | number,
    targetDomain?: string,
    path?: string | null,
    site?: Site,
  ) {
    super(id, name, hostname, site, path);
    this.trackedLinkPattern = targetDomain;
    this.trackingId = trackingId;
  }

  rewrite(url: string): string {
    // Rewrite only if trackedLinkPattern and trackingId have been provided
    if (!this.trackedLinkPattern || !this.trackingId) {
      return url;
    }

    const urlObject = new Uri(`https://${this.trackedLinkPattern}/`);
    urlObject.addQuery('AID', 1036697);
    urlObject.addQuery('PID', this.trackingId);
    const innerUrlObject = new Uri(url);
    urlObject.addQuery('r', Uri.decode(innerUrlObject.toString()));

    return urlObject.toString();
  }
}
