import { Site } from '../../types/Site';

const site: Site = {
  code: 'hawk',
  url: 'default',
  name: 'default',
  analyticsName: 'hawk',
  selector: 'a',
};
export default site;
