import Uri from 'urijs';

import type { Site } from '../../types/Site';

import BaseMerchant from './BaseMerchant';

export default class AWMerchant extends BaseMerchant {
  mid: string | number | null | undefined;

  accountId: string | number | null;

  constructor(
    id: number,
    name: string,
    mid: string | number | null | undefined,
    hostname: string,
    path?: string | null,
    site?: Site,
    accountId?: string | number | null,
  ) {
    super(id, name, hostname, site, path);
    this.mid = mid;
    this.trackedLinkPattern = 'awin1.com';
    this.accountId = accountId || 103504;
  }

  rewrite(url: string): string {
    // Replace the encoded hash with a real hash
    // Carphone Warehouse links with an encoded # break (including extra params in the hashbang)
    // So we encode the link before the hash and then simply append the hash
    const originalUrl = new Uri(url);
    const fragment = originalUrl.fragment();
    originalUrl.fragment(''); // Remove fragment from the original URL

    if (this.accountId && this.mid) {
      const urlObject = new Uri(`https://www.${this.trackedLinkPattern}/awclick.php`);
      urlObject.addQuery('awinmid', this.mid);
      urlObject.addQuery('awinaffid', this.accountId);
      urlObject.addQuery('clickref', 'hawk-custom-tracking');
      urlObject.addQuery('p', originalUrl.toString());
      urlObject.fragment(fragment); // Append the fragment to the "outer" URL
      return urlObject.toString();
    }

    return url;
  }
}
