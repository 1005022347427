import { HawkEvents } from '../../types/HawkEvents';
import { Site } from '../../types/Site';
import { LinkProcessor } from '../../utils/linkProcessor/LinkProcessor';

import { getLinks } from './getLinks';
import processLinks from './processLinks';

interface ProcessArticleEvent {
  element: HTMLElement;
}

interface HandleInfiniteScrollArticleParams {
  selector: string;
  processors: LinkProcessor[];
  site: Site;
  area: string;
  articleName: string;
  articleUrl: string;
  articleId: string | null;
}

export const handleInfiniteScrollArticle = ({
  selector,
  processors,
  site,
  area,
  articleName,
  articleUrl,
  articleId,
}: HandleInfiniteScrollArticleParams): void => {
  document.addEventListener(
    HawkEvents.HAWK_PROCESS_ARTICLE,
    async (event: CustomEventInit<ProcessArticleEvent>) => {
      if (event.detail?.element) {
        // Get links that are not in a widget.
        const { linksToProcess, widgets } = getLinks(selector, event.detail.element);

        // Rewrite existing links with HawkLinks + SkimLinks right away
        await processLinks(
          linksToProcess,
          processors,
          site,
          area,
          articleName,
          articleUrl,
          widgets,
          articleId,
        );
      }
    },
  );
};
