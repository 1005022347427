import { Site } from '../../types/Site';

const responsiveVanillaSelector = '#article-body a, #gallery-body a, .article-body a';
const progressiveVanillaSelector = '.article__body a, #article-body a, .article-body a';
const forumSelector = '.p-body-pageContent a';

const sites: Site[] = [
  {
    code: 'trd',
    url: 'techradar.com',
    name: 'techradar',
    vanillaName: 'techradar',
    analyticsName: 'trd',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'grd',
    url: 'gamesradar.com',
    name: 'gamesradar',
    vanillaName: 'gamesradar',
    analyticsName: 'grd',
    selector: responsiveVanillaSelector,
  },
  {
    code: 't3',
    url: 't3.com',
    name: 't3',
    vanillaName: 't3',
    analyticsName: 't3',
    selector: progressiveVanillaSelector,
  },
  {
    code: 'pcg',
    url: 'pcgamer.com',
    name: 'pcgamer',
    vanillaName: 'pcgamer',
    analyticsName: 'pcg',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'cbq',
    url: 'creativebloq.com',
    name: 'creativebloq',
    vanillaName: 'creativebloq',
    analyticsName: 'cbq',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'mrd',
    url: 'musicradar.com',
    name: 'musicradar',
    vanillaName: 'musicradar',
    analyticsName: 'mrd',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'theradar',
    url: 'theradar.com',
    name: 'theradar',
    analyticsName: 'theradar',
    vanillaName: 'theradar',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'dcw',
    url: 'digitalcameraworld.com',
    name: 'digitalcameraworld',
    vanillaName: 'digitalcameraworld',
    analyticsName: 'dcw',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'realhomes',
    url: 'realhomes.com',
    name: 'realhomes',
    analyticsName: 'realhomes',
    vanillaName: 'realhomes',
    selector: progressiveVanillaSelector,
  },
  {
    code: 'homebuilding',
    url: 'homebuilding.co.uk',
    name: 'homebuilding',
    analyticsName: 'homebuilding',
    vanillaName: 'homebuilding',
    selector: `article a, ${responsiveVanillaSelector}`,
  },
  {
    code: 'loudersound',
    url: 'loudersound.com',
    name: 'loudersound',
    analyticsName: 'loudersound',
    vanillaName: 'loudersound',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'guitarworld',
    url: 'guitarworld.com',
    name: 'guitarworld',
    analyticsName: 'guitarworld',
    vanillaName: 'guitarworld',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'whathifi',
    url: 'whathifi.com',
    name: 'whathifi',
    analyticsName: 'whathifi',
    vanillaName: 'whathifi',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'practicalcaravan',
    url: 'practicalcaravan.com',
    name: 'practicalcaravan',
    analyticsName: 'practicalcaravan',
    selector: 'article a',
  },
  {
    code: 'practicalmotorhome',
    url: 'practicalmotorhome.com',
    name: 'practicalmotorhome',
    analyticsName: 'practicalmotorhome',
    selector: 'article a',
  },
  {
    code: 'tomshardware',
    url: 'tomshardware.com',
    name: 'tomshardware',
    analyticsName: 'tomshardware',
    vanillaName: 'tomshardware',
    selector: `${responsiveVanillaSelector}, ${forumSelector}`,
  },
  {
    code: 'tomsguide',
    url: 'tomsguide.com',
    name: 'tomsguide',
    analyticsName: 'tomsguide',
    vanillaName: 'tomsguide',
    selector: `${responsiveVanillaSelector}, ${forumSelector}, #widgetArea12 a.visit-site-test`,
  },
  {
    code: 'toptenreviews',
    url: 'toptenreviews.com',
    name: 'toptenreviews',
    analyticsName: 'toptenreviews',
    vanillaName: 'toptenreviews',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'anandtech',
    url: 'anandtech.com',
    name: 'anandtech',
    analyticsName: 'anandtech',
    selector: '.articleContent a, .p-body-content a', // Sites, Forums
  },
  {
    code: 'laptopmag',
    url: 'laptopmag.com',
    name: 'laptopmag',
    analyticsName: 'laptopmag',
    vanillaName: 'laptopmag',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'avnetwork',
    url: 'avnetwork.com',
    name: 'avnetwork',
    analyticsName: 'avnetwork',
    vanillaName: 'avnetwork',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'techlearning',
    url: 'techlearning.com',
    name: 'techlearning',
    analyticsName: 'techlearning',
    vanillaName: 'techlearning',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'space',
    url: 'space.com',
    name: 'space',
    analyticsName: 'space',
    vanillaName: 'space',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'livescience',
    url: 'livescience.com',
    name: 'livescience',
    analyticsName: 'livescience',
    vanillaName: 'livescience',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'cyclingnews',
    url: 'cyclingnews.com',
    name: 'cyclingnews',
    analyticsName: 'cyclingnews',
    vanillaName: 'cyclingnews',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'bikeperfect',
    url: 'bikeperfect.com',
    name: 'bikeperfect',
    analyticsName: 'bikeperfect',
    vanillaName: 'bikeperfect',
    selector: responsiveVanillaSelector,
  },
  {
    code: '5gradar',
    url: '5gradar.com',
    name: '5gradar',
    analyticsName: '5gradar',
    vanillaName: '5gradar',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'tvtechnology',
    url: 'tvtechnology.com',
    name: 'tvtechnology',
    analyticsName: 'tvtechnology',
    vanillaName: 'tvtechnology',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'fourfourtwo',
    url: 'fourfourtwo.com',
    name: 'fourfourtwo',
    analyticsName: 'fourfourtwo',
    vanillaName: 'fourfourtwo',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'guitarplayer',
    url: 'guitarplayer.com',
    name: 'guitarplayer',
    analyticsName: 'guitarplayer',
    vanillaName: 'guitarplayer',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'gardeningetc',
    url: 'gardeningetc.com',
    name: 'gardeningetc',
    analyticsName: 'gardeningetc',
    vanillaName: 'gardeningetc',
    selector: progressiveVanillaSelector,
  },
  {
    code: 'nexttv',
    url: 'nexttv.com',
    name: 'nexttv',
    analyticsName: 'nexttv',
    vanillaName: 'nexttv',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'techlearninguniversity',
    url: 'techlearninguniversity.com',
    name: 'techlearninguniversity',
    vanillaName: 'techlearninguniversity',
    analyticsName: 'techlearninguniversity',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'womanandhome',
    url: 'womanandhome.com',
    name: 'womanandhome',
    analyticsName: 'womanandhome',
    vanillaName: 'womanandhome',
    selector: progressiveVanillaSelector,
  },
  {
    code: 'marieclaire',
    url: 'marieclaire.co.uk',
    name: 'marieclaire',
    analyticsName: 'marieclaire',
    vanillaName: 'marieclaireuk',
    selector: `${progressiveVanillaSelector}, article a, #ise-root a`, // Standard articles & Marie Claire Edit
  },
  {
    code: 'marieclaireusshop',
    url: 'marieclaire.com/shop',
    name: 'marieclaireusshop',
    vanillaName: 'marieclaireusedit',
    analyticsName: 'marieclaireusshop',
    selector: progressiveVanillaSelector, // Marie Claire US
    urlStage: 'marieclaire.com.master.van-qa.futureplc.engineering/shop/',
  },
  {
    code: 'marieclaireus',
    url: 'marieclaire.com',
    name: 'marieclaireus',
    vanillaName: 'marieclaireus',
    analyticsName: 'marieclaireus',
    selector: `${progressiveVanillaSelector}, .site-content a, #widgetArea12 a.visit-site-test`, // Marie Claire US
  },
  {
    code: 'advnture',
    url: 'advnture.com',
    name: 'advnture',
    vanillaName: 'advnture',
    analyticsName: 'advnture',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'whattowatch',
    url: 'whattowatch.com',
    name: 'whattowatch',
    vanillaName: 'whattowatch',
    analyticsName: 'whattowatch',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'goodtoknow',
    url: 'goodto.com',
    name: 'goodtoknow',
    vanillaName: 'goodto',
    analyticsName: 'goodtoknow',
    selector: `${progressiveVanillaSelector}, article a`,
  },
  {
    code: 'livingetc',
    url: 'livingetc.com',
    name: 'livingetc',
    analyticsName: 'livingetc',
    vanillaName: 'livingetc',
    selector: progressiveVanillaSelector,
  },
  {
    code: 'fitandwell',
    url: 'fitandwell.com',
    name: 'fitandwell',
    vanillaName: 'fitandwell',
    analyticsName: 'fitandwell',
    selector: progressiveVanillaSelector,
  },
  {
    code: 'idealhome',
    url: 'idealhome.co.uk',
    name: 'idealhome',
    vanillaName: 'idealhome',
    analyticsName: 'idealhome',
    selector: `${progressiveVanillaSelector}, .articleBody a, .amp-wp-article-content a`,
  },
  {
    code: 'cyclingweekly',
    url: 'cyclingweekly.com',
    name: 'cyclingweekly',
    vanillaName: 'cyclingweekly',
    analyticsName: 'cyclingweekly',
    selector: `${progressiveVanillaSelector}, .articleBody a`,
  },
  {
    code: 'decanter',
    url: 'decanter.com',
    name: 'decanter',
    analyticsName: 'decanter',
    selector: '.articleBody a',
  },
  {
    code: 'petsradar',
    url: 'petsradar.com',
    name: 'petsradar',
    vanillaName: 'petsradar',
    analyticsName: 'petsradar',
    selector: responsiveVanillaSelector,
  },
  {
    code: 'golfmonthly',
    url: 'golfmonthly.com',
    name: 'golfmonthly',
    vanillaName: 'golfmonthly',
    analyticsName: 'golfmonthly',
    selector: `${progressiveVanillaSelector}, #content a`,
  },
  {
    code: 'homesandgardens',
    url: 'homesandgardens.com',
    name: 'homesandgardens',
    vanillaName: 'homesandgardens',
    analyticsName: 'homesandgardens',
    selector: progressiveVanillaSelector,
  },
  {
    code: 'mbr',
    url: 'mbr.co.uk',
    name: 'mbr',
    analyticsName: 'mbr',
    selector: '#content a',
  },
  {
    code: 'myimperfectlife',
    url: 'myimperfectlife.com',
    name: 'myimperfectlife',
    vanillaName: 'myimperfectlife',
    analyticsName: 'myimperfectlife',
    selector: progressiveVanillaSelector,
  },
  {
    code: 'wp',
    url: 'windowscentral.com',
    name: 'windowscentral',
    vanillaName: 'windowscentral',
    analyticsName: 'wp',
    selector: `${responsiveVanillaSelector}, article a`,
  },
  {
    code: 'ac',
    url: 'androidcentral.com',
    name: 'androidcentral',
    vanillaName: 'androidcentral',
    analyticsName: 'ac',
    selector: `${responsiveVanillaSelector}, article a`,
  },
  {
    code: 'im',
    url: 'imore.com',
    name: 'imore',
    vanillaName: 'imore',
    analyticsName: 'im',
    selector: `${responsiveVanillaSelector}, article a`,
  },
  {
    code: 'cinemablend',
    url: 'cinemablend.com',
    name: 'cinemablend',
    vanillaName: 'cinemablend',
    analyticsName: 'cinemablend',
    selector: `${responsiveVanillaSelector}, article a`,
  },
  {
    code: 'horseandhound',
    url: 'horseandhound.co.uk',
    name: 'horseandhound',
    analyticsName: 'horseandhound',
    selector: '.post-main__inner a',
  },
  {
    code: 'countrylife',
    url: 'countrylife.co.uk',
    name: 'countrylife',
    analyticsName: 'countrylife',
    vanillaName: 'countrylife',
    selector: progressiveVanillaSelector,
  },
  {
    code: 'ybw',
    url: 'ybw.com',
    name: 'ybw',
    analyticsName: 'ybw',
    selector: '#content a',
  },
  {
    code: 'rugbyworld',
    url: 'rugbyworld.com',
    name: 'rugbyworld',
    analyticsName: 'rugbyworld',
    selector: '#content a',
  },
  {
    code: 'mby',
    url: 'mby.com',
    name: 'mby',
    analyticsName: 'mby',
    selector: '#content a',
  },
  {
    code: 'yachtingmonthly',
    url: 'yachtingmonthly.com',
    name: 'yachtingmonthly',
    analyticsName: 'yachtingmonthly',
    selector: '#content a',
  },
  {
    code: 'pbo',
    url: 'pbo.co.uk',
    name: 'pbo',
    analyticsName: 'pbo',
    selector: '#content a',
  },
  {
    code: 'yachtingworld',
    url: 'yachtingworld.com',
    name: 'yachtingworld',
    analyticsName: 'yachtingworld',
    selector: '#content a',
  },
  {
    code: 'wallpaper',
    url: 'wallpaper.com',
    name: 'wallpaper',
    vanillaName: 'wallpaper',
    analyticsName: 'wallpaper',
    selector: `${progressiveVanillaSelector}, .bodyLayer a`,
  },
  {
    code: 'theweekus',
    url: 'theweek.com',
    name: 'theweekus',
    vanillaName: 'theweek',
    analyticsName: 'theweekus',
    selector: `.polaris__app a, ${progressiveVanillaSelector}`,
  },
  {
    code: 'coachmag',
    url: 'coachweb.com',
    name: 'coachmag',
    alias: 'coachmag',
    vanillaName: 'coachmag',
    analyticsName: 'coachmag',
    selector: `${responsiveVanillaSelector}, .content a`,
  },
  {
    code: 'moneyweek',
    url: 'moneyweek.com',
    name: 'moneyweek',
    vanillaName: 'moneyweek',
    analyticsName: 'moneyweek',
    selector: `${progressiveVanillaSelector}, .polaris__app a`,
  },
  {
    code: 'kiplinger',
    url: 'kiplinger.com',
    name: 'kiplinger',
    vanillaName: 'kiplinger',
    analyticsName: 'kiplinger',
    selector: `${progressiveVanillaSelector}, .polaris__app a`,
  },
  {
    code: 'thefield',
    url: 'thefield.co.uk',
    name: 'thefield',
    analyticsName: 'thefield',
    selector: '#content a',
  },
  {
    code: 'getprice',
    url: 'getprice.com.au',
    name: 'getprice',
    vanillaName: 'getprice',
    analyticsName: 'getprice',
    selector: `${responsiveVanillaSelector}, main a`,
  },
  {
    code: 'getpricenz',
    url: 'getprice.co.nz',
    name: 'getpricenz',
    analyticsName: 'getpricenz',
    selector: 'main a',
  },
  {
    code: 'gardeningknowhow',
    url: 'gardeningknowhow.com',
    name: 'gardeningknowhow',
    vanillaName: 'gardeningknowhow',
    analyticsName: 'gardeningknowhow',
    selector: progressiveVanillaSelector,
  },
  {
    code: 'pricepandasg',
    url: 'pricepanda.com.sg',
    name: 'pricepandasg',
    analyticsName: 'pricepandasg',
    selector: 'main a',
  },
  {
    code: 'pricepandamy',
    url: 'pricepanda.com.my',
    name: 'pricepandamy',
    analyticsName: 'pricepandamy',
    selector: 'main a',
  },
  {
    code: 'pricepandaph',
    url: 'pricepanda.com.ph',
    name: 'pricepandaph',
    analyticsName: 'pricepandaph',
    selector: 'main a',
  },
  {
    code: 'pricepandaid',
    url: 'pricepanda.co.id',
    name: 'pricepandaid',
    analyticsName: 'pricepandaid',
    selector: 'main a',
  },
  {
    code: 'pricepandath',
    url: 'pricepanda.co.th',
    name: 'pricepandath',
    analyticsName: 'pricepandath',
    selector: 'main a',
  },
  {
    code: 'discover',
    url: 'discover.futureplc.com',
    name: 'discover',
    vanillaName: 'discover',
    analyticsName: 'discover',
    selector: progressiveVanillaSelector,
  },
  {
    code: 'mozo',
    url: 'mozo.com.au',
    name: 'mozo',
    analyticsName: 'mozo',
    selector: '[data-hawklinks] a',
  },
  {
    code: 'whowhatwear',
    url: 'whowhatwear.co',
    name: 'whowhatwear',
    vanillaName: 'whowhatwear',
    analyticsName: 'whowhatwear',
    selector: `${progressiveVanillaSelector}, #mainarticle a`,
    alias: 'whowhatwearpromo',
    urlStage: 'whowhatwear-promo.com',
  },
  {
    code: 'itprous',
    url: 'itpro.com',
    name: 'itprous',
    analyticsName: 'itprous',
    selector: `${responsiveVanillaSelector}, .polaris__app a`,
  },
  {
    code: 'myvouchercodes',
    url: 'myvouchercodes.co.uk',
    name: 'myvouchercodes',
    vanillaName: 'myvouchercodes',
    analyticsName: 'myvouchercodes',
    selector: progressiveVanillaSelector,
  },
  {
    code: 'shortlist',
    url: 'shortlist.com',
    name: 'shortlist',
    analyticsName: 'shortlist',
    selector: '#content a',
  },
];
export default sites;
